import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { Role } from '../enums'
import { auth, functions } from '../lib/firebase'
import { actionTypes, userReducer } from '../reducers/userReducer'
import { userIsPermitted } from '../utility/rbac'

export const UserContext = createContext()

export function UserProvider({ children }) {
  const [user, dispatch] = useReducer(userReducer, undefined)
  const [userBeingEdited, setUserBeingEdited] = useState()
  const [loading, setLoading] = useState(true)
  console.log({ user })
  const userHasRole = useCallback((allow) => userIsPermitted(allow, user), [user])

  const getUserRole = useCallback(async (user) => {
    const { claims } = await user.getIdTokenResult()
    if (claims.isAdmin) return [Role.ADMIN]
    if (claims.roles) return claims.roles.map((role) => Role[role.toUpperCase()])
    return [Role.VISITOR]
  }, [])

  const startEditingUser = (user) => {
    setUserBeingEdited(user)
  }

  const stopEditingUser = () => {
    setUserBeingEdited()
  }

  // Subscribe to auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const roles = await getUserRole(user)
        dispatch({ type: actionTypes.user.add, user: { ...user.toJSON(), roles } })
      } else {
        dispatch({ type: actionTypes.user.remove })
      }
      setLoading(false)
    })

    return unsubscribe
  }, [getUserRole])

  const login = async ({ email, password }) => {
    const { user } = await auth.signInWithEmailAndPassword(email, password)
    const roles = await getUserRole(user)

    if (user) dispatch({ type: actionTypes.user.add, user: { ...user.toJSON(), roles } })
    else dispatch({ type: actionTypes.user.remove })
    return user
  }

  const activateAccount = async ({ email, link, password }) => {
    await auth.signInWithEmailLink(email, link)
    return await changePassword(password)
  }

  const signout = async () => {
    await auth.signOut()
    return dispatch({ type: actionTypes.user.remove })
  }

  const changePassword = async (password) => {
    return await auth.currentUser.updatePassword(password)
  }

  const createUser = async (data) => await functions.authCreateUser(data)

  const updateUser = async ({ uid, data }) => {
    const newUser = await functions.authUpdateUser({ uid, user: data })
    dispatch({ type: actionTypes.user.edit, user: { ...newUser.data } })
  }

  const sendPasswordResetEmail = async ({ email }) => {
    return auth.sendPasswordResetEmail(email).then(() => {
      return true
    })
  }

  const confirmPasswordReset = (code, password) => {
    return auth.confirmPasswordReset(code, password).then(() => {
      return true
    })
  }

  const defaultValues = {
    activateAccount,
    changePassword,
    confirmPasswordReset,
    createUser,
    dispatch,
    login,
    sendPasswordResetEmail,
    setUserBeingEdited,
    startEditingUser,
    stopEditingUser,
    signout,
    updateUser,
    user,
    userBeingEdited,
    userHasRole
  }

  return <UserContext.Provider value={defaultValues}>{!loading && children}</UserContext.Provider>
}

// Hook
export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined)
    throw new Error('`useUser` hook must be used within a `UserProvider` component')
  return context
}
